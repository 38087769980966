var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"informationsGeneraux"},[_c('b-row',[_c('b-col',{staticClass:"pb-2",attrs:{"lg":"12"}},[_c('div',[_c('b-card',{attrs:{"id":"renseignementsgeneraux"}},[_c('b-card-header',{staticClass:"d-flex justify-content-between"},[_c('h3',[_vm._v("RENSEIGNEMENTS GÉNÉRAUX")])]),_c('hr'),_c('b-card-body',[_c('b-row',[_c('b-col',{staticClass:"mt-2",attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-cols-lg":"2","label":"DATE D'ARRIVEE AU BENIN","label-for":"date"}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"placeholder":"Date","value":"date","type":"date"},model:{value:(_vm.sendForm.dateArrivee),callback:function ($$v) {_vm.$set(_vm.sendForm, "dateArrivee", $$v)},expression:"sendForm.dateArrivee"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-row',[_c('b-col',{staticClass:"mt-2",attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-cols-lg":"2","label":"COMPAGNIE AÉRIENNE"}},[_c('validation-provider',{attrs:{"name":"Compagnie Aérienne","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Compagnie Aérienne"},model:{value:(_vm.sendForm.compagnieAerienne),callback:function ($$v) {_vm.$set(_vm.sendForm, "compagnieAerienne", _vm._n($$v))},expression:"sendForm.compagnieAerienne"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"mt-2",attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Pays de Résidence","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-cols-lg":"2","label":"PAYS DE RESIDENCE","label-for":"pays_residence"}},[_c('v-select',{attrs:{"type":"text","options":_vm.pays_residence,"label":"title","placeholder":"Pays de résidence","size":"sm"},model:{value:(_vm.sendForm.paysResidence),callback:function ($$v) {_vm.$set(_vm.sendForm, "paysResidence", $$v)},expression:"sendForm.paysResidence"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-row',[_c('b-col',{staticClass:"mt-2",attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Nationalité","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-cols-lg":"2","label":"NATIONALITE","label-for":"nationality"}},[_c('v-select',{attrs:{"type":"text","options":_vm.nationality,"label":"title","placeholder":"Nationalité"},model:{value:(_vm.sendForm.nationalite),callback:function ($$v) {_vm.$set(_vm.sendForm, "nationalite", $$v)},expression:"sendForm.nationalite"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1)],1)],1)],1)])],1),(_vm.showEditionButtons)?_c('b-row',[_c('b-col',{staticClass:"my-2 text-right",attrs:{"cols":"12"}},[_c('div',{},[_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":_vm.onCancel}},[_vm._v(" Cancel ")]),_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.submitForm}},[_vm._v(" Modifier ")])],1)])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
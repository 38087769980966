<template>
  <div class="mb-5">
    <b-card>
      <h1 class="text-center">
        Veuillez vous assurer que les informations entrées sont correctes puis
        enregistrez le formulaire
      </h1>
    </b-card>
    <b-card class="preview-document">
      <b-row>
        <b-col cols="12">
          <div v-if="infos" class="pl-1 pr-1">
            <!-- renseignement generaux -->
            <div class="bg-primary">
              <b-row class="text-center mt-1 p-1">
                <b-col
                  md="10"
                  class="text-left text-white d-flex align-items-center"
                >
                  <strong>RENSEIGNEMENTS GÉNÉRAUX </strong>
                </b-col>
                <b-col md="2" class="text-right text-white pr-2">
                  <b-img
                    title="Modifier cette section"
                    class="cursor-pointer"
                    width="20"
                    height="20"
                    :src="editIcon"
                    @click="editSection('renseignements-generaux')"
                  />
                </b-col>
              </b-row>
            </div>

            <div class="customborder1 p-2">
              <b-row>
                <b-col md="6" class="mb-1">
                  Date d'arrivée au Bénin :
                  <strong> {{ infos.dateArrivee }}</strong>
                </b-col>

                <b-col md="6" class="mb-1">
                  Compagnie aérienne :
                  <strong>{{ infos.compagnieAerienne }}</strong>
                </b-col>
              </b-row>

              <b-row>
                <b-col md="6" class="mb-1">
                  Pays de résidence : <strong>{{ infos.paysResidence }}</strong>
                </b-col>
                <b-col
                  v-if="infos.paysResidence != 'Benin'"
                  md="6"
                  class="mb-1"
                >
                  Nationalité : <strong>{{ infos.nationalite }}</strong>
                </b-col>
              </b-row>
            </div>

            <!-- Détails -->
            <span v-if="infos.paysResidence != 'Benin'">
              <div class="bg-primary">
                <b-row class="text-center mt-1 p-1">
                  <b-col
                    md="10"
                    class="text-left text-white d-flex align-items-center"
                  >
                    <strong> DÉTAILS </strong>
                  </b-col>
                  <b-col md="2" class="text-right text-white pr-2">
                    <b-img
                      title="Modifier cette section"
                      class="cursor-pointer"
                      width="20"
                      height="20"
                      :src="editIcon"
                      @click="editSection('details')"
                    />
                  </b-col>
                </b-row>
              </div>

              <div class="customborder1 p-2">
                <b-row>
                  <b-col md="6" class="mb-1">
                    Nom et prénom : <strong> {{ infos.fullname }}</strong>
                  </b-col>

                  <b-col md="6" class="mb-1">
                    Age : <strong>{{ infos.age }}</strong>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col md="6" class="mb-1">
                    Sexe : <strong>{{ infos.sexe }}</strong>
                  </b-col>
                  <b-col md="6" class="mb-1">
                    Motif principal du voyage :
                    <strong>{{ infos.motifVoyage }}</strong>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col md="6" class="mb-1">
                    Mode d'hébergemnt :
                    <strong>{{ infos.modeHebergement }}</strong>
                  </b-col>
                  <b-col md="6" class="mb-1">
                    Ville/Commune : <strong>{{ infos.villeCommune }}</strong>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="6" class="mb-1">
                    Durée du séjour : <strong>{{ infos.dureeSejour }}</strong>
                  </b-col>
                  <b-col md="6" class="mb-1">
                    Nom de l'agent enquêteur :
                    <strong>{{ infos.enqueteur }}</strong>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="12" class="mb-1">
                    Allez-vous recevoir une rémunération d'une entité du
                    pays/lieu visite durant votre séjour ? :
                    <strong>{{
                      infos.possibiliteRemuneration === true ? "Oui" : "Non"
                    }}</strong>
                  </b-col>
                </b-row>
              </div>
            </span>
          </div>
        </b-col>
      </b-row>
      <b-row class="px-1 mt-2">
        <b-col md="6">
          <div class="text-left">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="secondary"
              class="col-xl-4"
              @click="edit()"
            >
              Retourner sur le formulaire
            </b-button>
          </div>
        </b-col>
        <b-col md="6">
          <div class="text-right">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="col-xl-4"
              @click="save()"
            >
              <span v-if="isSavingForm" class="d-flex align-items-center"
                ><div class="mr-2"><b-spinner /></div>

                <span class=""> Validation du formulaire... </span>
              </span>
              <span v-if="!isSavingForm"> Enregistrer les informations </span>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-card>

    <!-- modal backdrop -->
    <b-modal
      :ref="sectionEditionModalRef"
      scrollable
      hide-footer
      no-close-on-backdrop
      content-class="shadow"
      title="Modifier cette section"
      size="xl"
    >
      <!-- <b-card-text>
        <span>We've added the utility class</span>
        <code>'shadow'</code>
        <span>to the modal content for added effect.</span>
      </b-card-text> -->
      <component
        :is="sectionToEditComponent"
        :input-data="sectionToEditData"
        :show-edition-buttons="true"
        @onFormSuccess="onEditionFormSuccess($event)"
        @onCancel="hideSectionEditionModal"
      />
    </b-modal>
  </div>
</template>

<script>
import {
  BCol,
  BRow,
  BButton,
  BCard,
  BCardHeader,
  BCardBody,
  BFormRadioGroup,
  BListGroup,
  BListGroupItem,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormRadio,
  BForm,
  BCardText,
  VBTooltip,
  BImg,
  BSpinner,
  BModal,
} from "bootstrap-vue";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
// import axios from 'axios'
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapActions, mapMutations } from "vuex";
import AppLogo from "@/components/AppLogo.vue";
import localstorageService from "@/services/localstorage/localstorage.service";
import utilsService from "@/services/utils/utils.service";
import FormRenseignementsGeneraux from "./sections/FormRenseignementsGeneraux.vue";
import FormDetails from "./sections/FormDetails.vue";

import formulaireStoreModule from "@/store/formulaire";

import {
  registerStoreModule,
  unregisterStoreModule,
} from "@/helpers/vuex-utils";

export default {
  setup() {
    const requiredStoreModules = [
      { path: "formulaire", module: formulaireStoreModule },
    ];
    // Register module
    registerStoreModule(requiredStoreModules);

    return { requiredStoreModules };
  },
  beforeDestroy() {
    unregisterStoreModule(this.requiredStoreModules);
  },
  components: {
    BCol,
    BRow,
    BButton,
    BCard,
    BCardHeader,
    BCardBody,
    BFormRadioGroup,
    BListGroup,
    BListGroupItem,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormRadio,
    BForm,
    BCardText,
    BSpinner,
    vSelect,
    ToastificationContent,
    AppLogo,
    VBTooltip,
    BImg,
    BModal,

    // renseignements generaux
    FormRenseignementsGeneraux,

    // details
    FormDetails,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      isSavingForm: false,
      infos: null,
      preview: null,
      isProcessingProvidedInput: true,
      sectionEditionModalRef: "edit-section-modal",
      sectionToEditComponent: null,
      sectionToEditData: null,
      editIconGreen: require("@/assets/images/icons/edit vert.png"),
      editIcon: require("@/assets/images/icons/edit.png"),
      currentUserId: localstorageService.getUserId(),
    };
  },
  computed: {
    layoutContentRenderer() {
      const rendererType = this.$route.meta.contentRenderer;
      if (rendererType === "sidebar-left") {
        return "layout-content-renderer-left";
      }
      if (rendererType === "sidebar-left-detached") {
        return "layout-content-renderer-left-detached";
      }
      return "layout-content-renderer-default";
    },
  },
  mounted() {
    if (this.$route.params.data && this.$route.params.preview) {
      const { data, isEditionOperation } = this.$route.params;

      // console.log('------------', data, isEditionOperation)

      this.infos = {
        ...data,
        // ...(isEditionOperation && { editor: this.currentUserId }),
      };
      if (isEditionOperation) this.infos.editor = this.currentUserId;

      this.preview = this.$route.params.preview;
      // console.log(this.preview, this.infos)
      // console.log('ok')
    } else {
      // this.infos = validInput
      // console.log('ok')
      this.$router.push({
        name: "formulaires-create",
        params: { slug: this.$route.params.slug },
      });
    }
  },
  methods: {
    ...mapMutations("auth", {
      callUserToRegister: "setter_callToRegister",
    }),
    ...mapActions("formulaire", {
      addFormulaire: "addFormulaire",
      updateFormulaire: "updateFormulaire",
    }),
    onEditionFormSuccess(evt) {
      this.infos = { ...this.infos, ...evt };
      this.hideSectionEditionModal();
      // console.log('onFormsuccess', evt)
    },
    // eslint-disable-next-line consistent-return
    getSectionToEditComponent(section) {
      switch (section) {
        case "renseignements-generaux":
          return "FormRenseignementsGeneraux";
        case "details":
          return "FormDetails";
        default:
          break;
      }
    },
    save() {
      const { currentUserId, etablissementDetails, userRoleId } =
        utilsService.currentUserUtils();

      // console.log('currentUserId: ', currentUserId);
      if (currentUserId) {
        // console.log('ok')
        this.infos.auteur = currentUserId;
        this.infos.auteurRole = userRoleId;
      } else {
        // console.log('vide')
        delete this.infos.auteur;
      }
      this.isSavingForm = true;
      // console.log('this.infos: ', this.infos)
      const { isEditionOperation } = this.$route.params;
      const successMessage = !isEditionOperation
        ? "Formulaire enregistré avec succès"
        : "Opération réussie";
      const serviceMethode = isEditionOperation
        ? "updateFormulaire"
        : "addFormulaire";
      const { _id } = this.infos;
      delete this.infos._id;

      if (!this.infos.nationalite) delete this.infos.nationalite;
      if (this.infos.dureeSejour == 0 || !this.infos.dureeSejour)
        delete this.infos.dureeSejour;
      if (!this.infos.motifVoyage) delete this.infos.motifVoyage;
      if (!this.infos.modeHebergement) delete this.infos.modeHebergement;
      if (!this.infos.possibiliteRemuneration)
        delete this.infos.possibiliteRemuneration;
      if (!this.infos.villeCommune) delete this.infos.villeCommune;
      if (!this.infos.age) delete this.infos.age;
      if (!this.infos.sexe) delete this.infos.sexe;
      if (!this.infos.fullname) delete this.infos.fullname;
      if (!this.infos.enqueteur) delete this.infos.enqueteur;

      console.log("======= this.infos: ===========", this.infos);

      const data = {
        formType: "forms-aeroport-entrees",
        ...(isEditionOperation && { id: _id }),
        data: {
          ...this.infos,
        },
      };
      if (isEditionOperation) data.data.editor = this.currentUserId;

      // delete data.data.typeEtablissement
      // if( currentUserId ) data.data.auteur = currentUserId
      // if (etablissementDetails._id) data.data.etablissement = etablissementDetails._id
      // if (etablissementDetails.secteur) data.data.secteur = etablissementDetails.secteur

      // console.log('------ finale data before save: ===> ',data,etablissementDetails)
      // console.log('------ finale data before save: ===> ',serviceMethode)
      // return

      // console.log('data ------------- : ', data)

      this[serviceMethode](data)
        .then((response) => {
          // console.log('============ response create form: ===========', response)
          // return

          if (response.status === 200) {
            if (
              this.$store.state.auth &&
              this.$store.state.auth.isAuthenticated
            ) {
              this.$router.push({
                name: "formulaires-list",
                params: { slug: this.$route.params.slug },
              });
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: successMessage,
                  icon: "CheckIcon",
                  variant: "success",
                  // text: 'Bienvenue !',
                },
              });
            } else {
              this.$router.push({
                name: "home-public",
              });
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: successMessage,
                  icon: "CheckIcon",
                  variant: "success",
                  // text: 'Bienvenue !',
                },
              });
            }

            const callToRegisterFormData = {
              nom: this.infos.firstname,
              prenom: this.infos.lastname,
              email: "",
              telephone: "",
              isOk: null,
            };
            this.callUserToRegister({
              status: true,
              data: callToRegisterFormData,
              isOk: false,
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Une erreur est suvenue, veuillez rééssayer",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
          this.isSavingForm = false;
        })
        .catch((error) => {
          this.isSavingForm = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title:
                error.message || "Une erreur est suvenue, veuillez rééssayer",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },
    showSectionEditionModal() {
      this.$refs[this.sectionEditionModalRef].show();
    },
    hideSectionEditionModal() {
      this.$refs[this.sectionEditionModalRef].hide();
    },
    editSection(section) {
      this.sectionToEditComponent = this.getSectionToEditComponent(section);
      this.sectionToEditData = JSON.parse(JSON.stringify(this.infos));

      this.showSectionEditionModal();
    },
    edit() {
      this.$router.push({
        name: "formulaires-create",
        params: { slug: this.$route.params.slug, data: this.infos },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/base/themes/bordered-layout.scss";

.customborder1 {
  border: 1px solid rgba(0, 0, 0, 0.3);
  // padding: 15px 35px;
}
.customborder2 {
  border: 1px solid rgba(0, 0, 0, 0.3);
  // padding: 0 35px 15px;
}

table {
  td,
  th,
  tr {
    text-align: left;
  }
}
thead {
  th {
    background-color: #0e6258 !important;
    color: white;
  }
}
</style>
